export default function products(event) {
  return {
    專區牌位: {
      id: 'fb030d0a-d20e-443e-b7db-663196e14178',
      name: '專區牌位',
      price: 12000,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%B0%88%E5%8D%80%E7%89%8C%E4%BD%8D.jpg',
    },
    特種牌位: {
      id: '96887699-517f-483a-8e85-064527eefa63',
      name: '特種牌位',
      price: event.substring(3) === '新春' ? 2000 : 3000,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E7%89%B9%E7%A8%AE%E7%89%8C%E4%BD%8D.jpg',
    },
    甲種牌位: {
      id: 'fc37e10b-0f26-41d1-9459-51d93e2a01ee',
      name: '甲種牌位',
      price: 2000,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E7%94%B2%E7%A8%AE%E7%89%8C%E4%BD%8D.jpg',
    },
    如意套組: {
      id: '2426bcf0-5acb-4bcf-867d-e2a629c073fd',
      name: '如意套組',
      price: 1100,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%A6%82%E6%84%8F%E5%A5%97%E7%B5%84.jpg',
    },
    吉祥套組: {
      id: '3ea574cf-a82a-4822-8477-297b5d184c65',
      name: '吉祥套組',
      price: 1500,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%90%89%E7%A5%A5%E5%A5%97%E7%B5%84.jpg',
    },
    思親套組: {
      id: '102a6091-1d2f-432c-837c-bb77e07cf93a',
      name: '思親套組',
      price: 2200,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E6%80%9D%E8%A6%AA%E5%A5%97%E7%B5%84.jpg',
    },
    '思親套組(2)': {
      id: '16fea64e-d69e-430b-adae-4c8b925bbb53',
      name: '思親套組(2)',
      price: 2200,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E6%80%9D%E8%A6%AA%E5%A5%97%E7%B5%84.jpg',
    },
    // 黃金套組: {
    //   id: 'eb82bb43-b616-417d-ad94-801e2199e6fa',
    //   name: '黃金套組',
    //   price: 800,
    //   image:
    //     'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E9%BB%83%E9%87%91%E5%A5%97%E7%B5%84.jpg',
    // },
    富貴套組: {
      id: '21bb2ea4-cce2-455e-9071-714ce65aea4e',
      name: '富貴套組',
      price: 1700,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%AF%8C%E8%B2%B4%E5%A5%97%E7%B5%84.jpg',
    },
    供燈: {
      id: 'af353f1b-9617-49b3-a4bc-25ba40478b3a',
      name: '供燈',
      price: 300,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E4%BE%9B%E7%87%88.jpg',
    },
    光明燈: {
      id: '88274a38-4a1d-4e02-a63e-5f18a0672e2d',
      name: '光明燈',
      price: 500,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%85%89%E6%98%8E%E7%87%88.jpg',
    },
    // 測試品項: {
    //   id: '154ab246-0bf4-41d9-99f0-710e6af17881',
    //   name: '測試品項',
    //   price: 10,
    //   image:
    //     'https://thenounproject.com/api/private/icons/3538848/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0&token=gAAAAABjexdX3641ySZi6Ph1IicheC2NBhqgLuVitpbObaSB8Hgkj7CyoTy0WCoK6eEPYoqq9tfF4Zj5UVwqc-S_FBIfwgOv2g%3D%3D',
    // },
    '年度優惠-甲種': {
      id: '664073ec-9ad4-4439-b450-fb82cc4c70f3',
      name: '年度優惠-甲種',
      price: 5760,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E7%94%B2%E7%A8%AE%E5%84%AA%E6%83%A0.jpg',
    },
    '年度優惠-特種': {
      id: 'c5c81bda-2125-4209-bc91-da030178c297',
      name: '年度優惠-特種',
      price: 7680,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E7%89%B9%E7%A8%AE%E5%84%AA%E6%83%A0.jpg',
    },
    '年度優惠-如意': {
      id: '172cb6c0-34c0-4d2c-92bc-96775b8b5476',
      name: '年度優惠-如意',
      price: 3170,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%A6%82%E6%84%8F%E5%84%AA%E6%83%A0.jpg',
    },
    '年度優惠-吉祥': {
      id: '1a66f137-01ca-4d76-84c8-28b136e08aac',
      name: '年度優惠-吉祥',
      price: 4280,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E5%90%89%E7%A5%A5%E5%84%AA%E6%83%A0.jpg',
    },
    '年度優惠-思親': {
      id: '1d55c336-1b2f-46d4-8377-f09b429e644c',
      name: '年度優惠-思親',
      price: 6200,
      image:
        'https://dinfucemstorage.blob.core.windows.net/image/dinfucem/%E6%80%9D%E8%A6%AA%E5%84%AA%E6%83%A0.jpg',
    },
  };
}
