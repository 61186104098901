import {
  Alert,
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import getProducts from './product-list';
import { useCart } from './cart-service';

const styles = {
  container: {
    px: { xs: 3, sm: 8, md: 12 },
    pt: 3,
    pb: 15,
    maxWidth: 1000,
    m: '0 auto',
  },
  warning: {
    color: '#ed6c02',
  },
  price: {
    fontSize: 25,
    color: '#219EBC',
    letterSpacing: '0.05rem',
    marginBottom: 50,
  },
  productImage: {
    maxHeight: {
      xs: 300,
      sm: 300,
      md: 500,
    },
    maxWidth: {
      xs: 300,
      sm: 300,
      md: 500,
    },
    margin: '0 auto',
  },
  sectionLabel: {
    color: 'rgba(0,0,0,0.7)',
    fontSize: 12,
    display: { xs: 'none', sm: 'flex' },
  },
  familyLabel: {
    display: 'inline-block',
    width: { xs: 150, sm: 60, md: 150 },
    paddingTop: 0.8,
  },
  familyTextField: { width: { sm: 145, md: 194 } },
  addButton: {
    largeScreen: {
      p: {
        sm: 1,
        md: 2,
      },
      display: { xs: 'none', sm: 'block' },
    },
    smallScreen: {
      display: {
        xs: 'block',
        sm: 'none',
      },
      boxSizing: 'border-box',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: 90,
      backgroundColor: 'rgb(225,225,225)',
      padding: 2,
      '.MuiButton-root': {
        p: 2,
      },
    },
  },
};

function AddProduct(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = Object.entries(getProducts(process.env.REACT_APP_EVENT)).find(
    ([key, value]) => value.id === id
  )[1];
  const { cart, setCart } = useCart();
  const event = process.env.REACT_APP_EVENT;
  const [item, setItem] = React.useState({
    event,
    name: product.name,
    price: product.price,
    deceaseds: cart.recipient?.deceaseds ? cart.recipient.deceaseds : ['', ''],
    family: cart.recipient?.family
      ? cart.recipient.family
      : ['', '', '', '', ''],
    errors: {},
  });

  function handleClear() {
    setItem((prev) => ({
      ...prev,
      deceaseds: ['', ''],
      family: ['', '', '', '', ''],
      errors: {},
    }));
  }
  function handleChange(field, index, value) {
    setItem((prev) => {
      const array = [...prev[field]];
      array[index] = value.trim();
      return {
        ...prev,
        [field]: array,
      };
    });
  }
  function handleAdd() {
    const errors = {};
    if (item.deceaseds.every((d) => !d)) errors.noDeceased = '請輸入被超薦人';
    item.deceaseds.every((d, index) => {
      let result =
        /[\s,、，（）～！⋯⋯——「」｜：“”《》？~!@#$%^&*_+{}:"<>?/-;.`\[\]·|’‘¥€£•]/.test(
          d
        );
      if (result) {
        errors[`deceaseds${index}`] = true;
        errors.specialCharacter = '請勿輸入特殊符號';
      }
    });
    if (item.family.every((f) => !f)) errors.noFamily = '請輸入陽上';
    for (let i = 0; i < item.family.length; i++) {
      if (item.family[i].length > 2) {
        if (!errors.family) errors.family = [];
        errors.family[i] = '請取陽上姓名後二字';
      }
    }
    if (Object.keys(errors).length > 0) {
      return setItem((prev) => ({
        ...prev,
        errors,
      }));
    }
    setCart((prev) => {
      const insert = { ...item, error: undefined, timestamp: Date.now() };
      insert.deceaseds = insert.deceaseds.filter((d) => d);
      insert.family = insert.family.filter((f) => f);
      const newCart = {
        ...prev,
        items: [...prev.items, insert],
        recipient: {
          deceaseds: insert.deceaseds,
          family: insert.family,
        },
      };
      return newCart;
    });
    navigate('/');
  }

  return (
    <>
      <Box sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.productImage}>
              <img width="100%" src={product.image} alt={product.name} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ ml: { xs: 0, sm: 5 } }}>
              <p
                style={{
                  color: 'rgba(0,0,0,0.5)',
                  marginTop: 0,
                  marginBottom: '5px',
                }}>
                {process.env.REACT_APP_EVENT.substring(0, 3)}年
                {process.env.REACT_APP_EVENT.substring(3)}法會
              </p>
              <h1 style={{ fontWeight: 'normal', marginTop: 0 }}>
                {product.name}
              </h1>
              {process.env.REACT_APP_EVENT.substring(3) !== '新春' &&
                product.name.substring(0, 2) === '思親' && (
                  <p style={styles.warning}>
                    {`思親套組內熟食部份僅祭祀一天，您選擇祭祀熟食的日期為法會第${
                      /2/.test(product.name) ? '二' : '一'
                    }天。`}
                  </p>
                )}
              <p style={styles.price}>${product.price.toLocaleString()}</p>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleAdd}
                fullWidth
                sx={styles.addButton.largeScreen}>
                加入購物車
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius: 1,
                p: { xs: 2.5, sm: 3, md: 4 },
              }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 5 }}>
                <Grid item>
                  <Typography
                    variant="p"
                    sx={{
                      color: '#219EBC',
                      fontSize: 20,
                    }}>
                    祭祀資料
                    <span style={{ color: 'red', fontSize: 16 }}> (必填)</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleClear}>
                    重新填寫
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: -4,
                    mb: 2,
                    color: 'rgba(0,0,0,0.3)',
                    fontSize: '12px',
                    display: {
                      sm: 'none',
                    },
                  }}>
                  · 請填寫至多兩位被超薦人
                  <br />· 陽上只取名字後二字
                </Grid>
                <Grid item sm={12} sx={styles.sectionLabel}>
                  <Tooltip title="請填寫至多兩位被超薦人" placement="top">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      逝者
                      <InfoIcon
                        fontSize="small"
                        sx={{ pb: 0.07, pl: 0.1, color: 'rgba(0,0,0,0.4)' }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                {item.errors.noDeceased && (
                  <Grid item xs={12}>
                    <Alert severity="error">{item.errors.noDeceased}</Alert>
                  </Grid>
                )}
                {item.errors.specialCharacter && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {item.errors.specialCharacter}
                    </Alert>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 150,
                      color: item.errors.deceaseds1 && 'red',
                    }}>
                    被超薦人(左)
                    <Box
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                        fontSize: 12,
                        mt: 0.2,
                      }}>
                      (逝者)
                    </Box>
                  </span>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.deceaseds[0] || ''}
                    error={!!item.errors.deceaseds0}
                    onChange={(event) =>
                      handleChange('deceaseds', 0, event.target.value)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 150,
                      color: item.errors.deceaseds1 && 'red',
                    }}>
                    被超薦人(右)
                  </span>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.deceaseds[1] || ''}
                    error={!!item.errors.deceaseds1}
                    onChange={(event) =>
                      handleChange('deceaseds', 1, event.target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ mt: { xs: 5 }, color: 'rgba(0,0,0,0.8)' }}>
                <Grid item sm={12} sx={styles.sectionLabel}>
                  <Tooltip title="陽上只取名字後二字" placement="top">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      親友家屬
                      <InfoIcon
                        fontSize="small"
                        sx={{ pb: 0.07, pl: 0.1, color: 'rgba(0,0,0,0.4)' }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                {item.errors.noFamily && (
                  <Grid item xs={12}>
                    <Alert severity="error">{item.errors.noFamily}</Alert>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Box sx={styles.familyLabel}>
                    陽上 1
                    <Box
                      sx={{
                        display: { xs: 'inline-block', sm: 'none' },
                        fontSize: 12,
                        ml: 0.2,
                      }}>
                      (親友家屬)
                    </Box>
                  </Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.family[0] || ''}
                    onChange={(event) =>
                      handleChange('family', 0, event.target.value)
                    }
                    error={!!item.errors.family?.[0]}
                    helperText={item.errors.family?.[0]}
                    sx={styles.familyTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={styles.familyLabel}>陽上 2</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.family[1] || ''}
                    onChange={(event) =>
                      handleChange('family', 1, event.target.value)
                    }
                    error={!!item.errors.family?.[1]}
                    helperText={item.errors.family?.[1]}
                    sx={styles.familyTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={styles.familyLabel}>陽上 3</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.family[2] || ''}
                    onChange={(event) =>
                      handleChange('family', 2, event.target.value)
                    }
                    error={!!item.errors.family?.[2]}
                    helperText={item.errors.family?.[2]}
                    sx={styles.familyTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={styles.familyLabel}>陽上 4</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.family[3] || ''}
                    onChange={(event) =>
                      handleChange('family', 3, event.target.value)
                    }
                    error={!!item.errors.family?.[3]}
                    helperText={item.errors.family?.[3]}
                    sx={styles.familyTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={styles.familyLabel}>陽上 5</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.family[4] || ''}
                    onChange={(event) =>
                      handleChange('family', 4, event.target.value)
                    }
                    error={!!item.errors.family?.[4]}
                    helperText={item.errors.family?.[4]}
                    sx={styles.familyTextField}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.addButton.smallScreen}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleAdd}
          fullWidth>
          加入購物車
        </Button>
      </Box>
    </>
  );
}

export default AddProduct;
