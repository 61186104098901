import './App.css';

import Closed from './Closed';
import Open from './Open';

function App() {
  return <>{process.env.REACT_APP_OPEN === 'true' ? <Open /> : <Closed />}</>;
}

export default App;
