import { AppBar, Box, Card, Grid, InputBase, Toolbar } from '@mui/material';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { styled } from '@mui/material/styles';

const SearchInput = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(0,0,0,0.10)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

let year = DateTime.now().year - 1911;
let event = '新春';
const month = DateTime.now().month;
if (month === 11 || month === 12) year = year + 1;
if (month >= 3 && month <= 5) event = '清明';
if (month >= 6 && month <= 9) event = '中元';
event = `${year}${event}`;

function Search(props) {
  const [keyword, setKeyword] = React.useState('');
  const [results, setResults] = React.useState();

  function handleKeyDown(e) {
    if (keyword.length > 1 && e.key === 'Enter') {
      const query = keyword;
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/event?keyword=${keyword}`)
        .then((response) => {
          const registrations = response.data.registrations;
          const records = [];
          const regex = new RegExp(
            response.data.type === 'full'
              ? new RegExp(query)
              : new RegExp(query.substr(-2))
          );
          for (let registration of registrations) {
            for (let item of registration.items) {
              if (
                item.event === event &&
                (item.deceaseds.some((d) => regex.test(d)) ||
                  item.family.some((f) => regex.test(f)))
              ) {
                records.push({
                  ...registration,
                  items: undefined,
                  ...item,
                  regId: registration.id,
                });
              }
            }
          }
          console.log(JSON.stringify(records, null, 2));
          setResults(records);
          setKeyword('');
        });
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar
          sx={{
            backgroundColor: 'white',
          }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Link to="/">
                <img
                  width="200"
                  src="https://dinfucemstorage.blob.core.windows.net/image/dinfucem/logo.png"
                  alt="logo"
                />
              </Link>
            </Grid>
            <Grid item>
              <SearchInput sx={{ marginTop: 1.5 }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="查詢牌位..."
                  value={keyword}
                  onChange={(event) => setKeyword(event.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchInput>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box>
        {results &&
          (results.length === 0 ? (
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item xs={12}>
                <h2
                  style={{
                    textAlign: 'center',
                  }}>
                  查無紀錄
                </h2>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} sx={{ padding: 2 }}>
              {results.map((record, index) => (
                <Grid id={`${record.sn}-${index}`} item xs={12} sm={6} lg={4}>
                  <Card>
                    <Box
                      sx={{
                        backgroundColor: 'rgba(0,0,0,0.08)',
                        p: 2,
                        fontSize: 18,
                      }}>{`${record.name}（${
                      record.registry === '劃撥'
                        ? `劃撥回條第 ${record.sn} 號`
                        : `${record.registry}報名第 ${
                            record.registry === '網路' ||
                            record.registry === '預繳'
                              ? record.sn.substring(19).toUpperCase()
                              : record.sn
                          } 號`
                    }）`}</Box>
                    <Box
                      sx={(theme) => ({
                        p: 2,
                        '.location': {
                          fontSize: 20,
                          color: theme.palette.primary.main,
                          fontWeight: 500,
                        },
                      })}>
                      {record.name === '專區牌位' && (
                        <div className="location">專區牌位</div>
                      )}
                      {record.name === '甲種牌位' ||
                      record.name === '特種牌位' ? (
                        <div className="location">{`${record.location
                          .substring(0, 2)
                          .replace(/^0+/, '')} 層 ${record.location
                          .substring(2)
                          .replace(/^0+/, '')} 號`}</div>
                      ) : record.name === '光明燈' ? (
                        <div className="location">{`${
                          record.location.substring(0, 1) === 'L' ? '左' : '右'
                        } ${record.location
                          .substring(1, 3)
                          .replace(/^0+/, '')} 層 ${record.location
                          .substring(3)
                          .replace(/^0+/, '')} 號`}</div>
                      ) : (
                        record.name !== '專區牌位' && (
                          <div className="location">
                            第 {record.location} 組
                          </div>
                        )
                      )}
                      <div
                        style={{
                          marginTop: 15,
                          marginBottom: 5,
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                        }}>
                        被超薦人
                      </div>
                      <div>{record.deceaseds.join('、')}</div>
                      <div
                        style={{
                          marginTop: 15,
                          marginBottom: 5,
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                        }}>
                        陽上
                      </div>
                      <div>{record.family.join('、')}</div>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ))}
      </Box>
    </Box>
  );
}

export default Search;
