import { DateTime } from 'luxon';
import React from 'react';
import useStorageState from './useStorageState';

const CartContext = React.createContext();

export function useCart() {
  const cart = React.useContext(CartContext);
  return cart;
}

function CartProvider({ children }) {
  const [cart, setCart] = useStorageState(localStorage, 'cart', {
    recipient: null,
    items: [],
    expiry: DateTime.now().plus({ minute: 1 }).toMillis(),
  });

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
}

export default CartProvider;
