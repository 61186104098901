import { Table, TableBody, TableCell, TableRow } from '@mui/material';

import { Box } from '@mui/system';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

function PaymentResult(props) {
  const [params] = useSearchParams();
  const result = Object.fromEntries(params);
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 8, md: 12 },
        pt: 3,
        pb: 10,
      }}>
      <Box
        sx={{
          p: 1.5,
          backgroundColor: 'rgb(212,235,206)',
          borderRadius: 2,
          fontSize: 25,
          textAlign: 'center',
          color: 'rgb(43,96,50)',
        }}>
        {result.PaymentType === 'VACC' ? '帳號' : '超商代碼'}：
        {result.PaymentType === 'VACC' && `${result.BankCode}-`}
        {result.CodeNo}
      </Box>
      <Box>
        <p>
          請於期限內付費以完成報名手續，付費後系統自動開立發票並以簡訊通知。
        </p>
      </Box>
      <Box>
        <Box
          sx={{
            fontSize: 20,
            color: 'rgb(67,163,160)',
            borderBottom: '2px solid rgb(208,232,231)',
            pb: 1.5,
          }}>
          付費資訊
        </Box>
        <Table
          sx={{
            td: { fontSize: 16, color: 'rgb(0,0,0,0.75)' },
            'tr td:nth-of-type(1)': { fontWeight: 'bold' },
          }}>
          <TableBody>
            <TableRow>
              <TableCell>訂單編號</TableCell>
              <TableCell>
                {result.MerchantOrderNo.slice(-6).toUpperCase()}
              </TableCell>
            </TableRow>
            {result.PaymentType === 'VACC' && (
              <TableRow>
                <TableCell>銀行代碼</TableCell>
                <TableCell>{result.BankCode}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                {result.PaymentType === 'VACC' ? '轉帳帳號' : '超商代碼'}
              </TableCell>
              <TableCell>{result.CodeNo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>訂單金額</TableCell>
              <TableCell sx={{ letterSpacing: '0.04rem' }}>
                ${Number(result.Amt).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span style={{ color: 'red' }}>付費期限</span>
              </TableCell>
              <TableCell>
                <span style={{ color: 'red' }}>{result.ExpireDate}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ li: { marginBottom: 1 } }}>
        <Box
          sx={{
            fontSize: 20,
            color: 'rgb(67,163,160)',
            borderBottom: '2px solid rgb(208,232,231)',
            pb: 1.5,
            mt: 5,
          }}>
          注意事項
        </Box>
        <ul
          style={{
            marginLeft: -10,
            color: 'rgba(0,0,0,0.7)',
            fontSize: 16,
            fontFamily: 'sans-serif',
          }}>
          {result.PaymentType === 'VACC' ? (
            <>
              <li>
                請記錄您的繳費資訊，並於期限內完成繳費，逾期轉帳帳號將失效。
              </li>
              <li>若轉帳帳號失效，請勿繳款，重新報名取得新的轉帳帳號。</li>
              <li>您可使用網路銀行或至全台任一ATM自動櫃員機進行轉帳。</li>
              <li>
                使用「第一銀行」及「彰化銀行」ATM自動櫃員機時，請選擇「繳費」功能，請勿使用「轉帳」功能，否則出現錯誤。
              </li>
              <li>若逾期報名自動作廢。</li>
            </>
          ) : (
            <>
              <li>
                請記錄您的繳費資訊，並於期限內完成繳費，逾期繳費代碼將失效。
              </li>
              <li>若繳費代碼失效，請勿繳款，重新報名取得新的繳費代碼。</li>
              <li>
                請至全台7-11、全家、OK、萊爾富超商內之多媒體機台（ibon、FamiPort、OK-GO、Life-ET）上列印繳費單。
              </li>
              <li>
                操作方式請參考：
                <a
                  href="https://www.newebpay.com/website/Page/content/cvs_intro"
                  target="_blank"
                  rel="noreferrer">
                  四大超商繳費流程
                </a>
              </li>
              <li>若逾期本筆訂單自動作廢。</li>
            </>
          )}
        </ul>
      </Box>
    </Box>
  );
}

export default PaymentResult;
