import {
  AppBar,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from './cart-service';

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState();
  const { cart } = useCart();
  const [search, setSearch] = React.useState({
    open: false,
    name: cart.name || '',
    mobile: cart.mobile || '',
  });
  const navigate = useNavigate();

  function handleOpenNavMenu(event) {
    setAnchorElNav(event.currentTarget);
  }
  function handleCloseNavMenu() {
    setAnchorElNav(null);
  }
  function handleClickCart() {
    if (cart.items.length > 0) {
      navigate('/cart');
      return null;
    }
  }
  function handleKeyDown(event) {
    if (
      (event.key === 'Enter' || event.key === 'NumpadEnter') &&
      search.name &&
      search.mobile.length === 10
    ) {
      handleSearch();
    }
  }
  function handleSearch() {
    navigate(`/regs?name=${search.name}&mobile=${search.mobile}`);
    setSearch({ name: '', mobile: '', open: false });
  }

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ position: 'relative' }}>
            {/** Large Screen Logo */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, width: 200 }}>
              <Link to="/">
                <img
                  width="100%"
                  src="https://dinfucemstorage.blob.core.windows.net/image/dinfucem/logo.png"
                  alt="logo"
                />
              </Link>
            </Box>
            {/** Nav Menu and Button */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
              }}>
              <IconButton
                size="large"
                aria-label="nav menu button"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit">
                <MenuIcon fontSize="large" />
              </IconButton>
              <Drawer
                anchor="left"
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}>
                <Box
                  sx={{ width: '60vw' }}
                  onClick={handleCloseNavMenu}
                  onKeyDown={handleCloseNavMenu}>
                  <List
                    sx={{
                      width: 200,
                      margin: '100px auto 0',
                      a: {
                        color: 'rgba(0,0,0,0.7)',
                        textDecoration: 'none',
                        '&:hover': { color: 'rgba(0,0,0,0.8)' },
                      },
                      li: {
                        fontSize: 20,
                        mb: 3,
                      },
                    }}>
                    <ListItem>
                      <Box
                        sx={{
                          width: 170,
                          ml: -1.6,
                          mb: 2.5,
                        }}>
                        <Link to="/">
                          <img
                            width="100%"
                            src="https://dinfucemstorage.blob.core.windows.net/image/dinfucem/logo.png"
                            alt="logo"
                          />
                        </Link>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <a
                        href={process.env.REACT_APP_PDF_URL}
                        target="_blank"
                        rel="noreferrer">
                        法會通知單
                      </a>
                    </ListItem>
                    <ListItem>
                      <Box
                        onClick={() => setSearch({ ...search, open: true })}
                        sx={{
                          cursor: 'pointer',
                          color: 'rgba(0,0,0,0.7)',
                          '&:hover': { color: 'rgba(0,0,0,0.8)' },
                        }}>
                        報名紀錄
                      </Box>
                    </ListItem>
                    {DateTime.fromISO(process.env.REACT_APP_EVENT_DATE).minus({
                      days: 5,
                    }) < DateTime.utc() && (
                      <ListItem>
                        <Link to="/event">牌位查詢</Link>
                      </ListItem>
                    )}
                    <ListItem>
                      <a
                        href="https://page.line.me/dinfu?openQrModal=true"
                        target="_blank"
                        rel="noreferrer">
                        線上客服
                      </a>
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
            </Box>
            {/** Small Screen Logo */}
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 200,
              }}>
              <Link to="/">
                <img
                  width="100%"
                  src="https://dinfucemstorage.blob.core.windows.net/image/dinfucem/logo.png"
                  alt="logo"
                />
              </Link>
            </Box>
            {/** Large Screen Nav Links */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                ml: 1.5,
                'a, span': {
                  color: 'rgba(0,0,0,0.7)',
                  textDecoration: 'none',
                  fontSize: 16,
                  marginRight: 3,
                  cursor: 'pointer',
                  ':hover': {
                    color: 'rgba(0,0,0,0.8)',
                  },
                },
              }}>
              <a
                href={process.env.REACT_APP_PDF_URL}
                target="_blank"
                rel="noreferrer">
                法會通知單
              </a>
              <span onClick={() => setSearch({ ...search, open: true })}>
                報名紀錄
              </span>
              {DateTime.fromISO(process.env.REACT_APP_EVENT_DATE).minus({
                days: 5,
              }) < DateTime.utc() && <Link to="/event">牌位查詢</Link>}
              <a
                href="https://page.line.me/dinfu?openQrModal=true"
                target="_blank"
                rel="noreferrer">
                線上客服
              </a>
            </Box>
            {/** Shopping Cart */}
            <Box sx={{ flexGrow: 0 }}>
              <IconButton sx={{ p: 0 }} onClick={handleClickCart}>
                <div style={{ position: 'relative', color: '#555' }}>
                  <ShoppingCartIcon sx={{ mb: -1.35, fontSize: '32px' }} />
                  <br />
                  <Typography>購物車</Typography>
                  {cart.items.length > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: '#219EBC',
                        color: 'white',
                        top: 0,
                        right: 0,
                        borderRadius: '50%',
                        width: 21,
                        height: 21,
                        fontSize: 15,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      {cart.items.length}
                    </div>
                  )}
                </div>
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Dialog
        open={search.open}
        onClose={() => setSearch({ name: '', mobile: '', open: false })}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>報名紀錄</DialogTitle>
        <Grid container spacing={3} sx={{ px: 3, pb: 4, pt: 1 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="姓名"
              value={search.name}
              onChange={(event) =>
                setSearch({ ...search, name: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="電話"
              value={search.mobile}
              onChange={(event) =>
                setSearch({ ...search, mobile: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                !search.name ||
                !search.mobile ||
                search.mobile.length !== 10 ||
                search.mobile.substring(0, 2) !== '09'
              }
              onClick={handleSearch}
              fullWidth>
              查詢
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Header;
