import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import AddProduct from './AddProduct';
import Cart from './Cart';
import Checkout from './Checkout';
import Header from './Header';
import Home from './Home';
import Orders from './Orders';
import PaymentResult from './PaymentResult';
import React from 'react';
import Search from './Search';
import Tutorial from './Tutorial';
import useCheckCartInterval from './useCheckCartInterval';

function Open(props) {
  useCheckCartInterval(1000 * 30);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/event" element={<Search />} />
          <Route path="/" element={<Layout />}>
            <Route index path="/" element={<Home />} />
            <Route path="/product/:id" element={<AddProduct />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/result" element={<PaymentResult />} />
            <Route path="/regs" element={<Orders />} />
            <Route path="/tutorial" element={<Tutorial />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default Open;
