import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const statusColors = {
  未取號: '#e9658f',
  尚未付費: '#80b8e9',
  待開立發票: '#ebb93e',
  逾期作廢: '#b4050a',
  成功報名: '#98bc76',
};

function Orders(props) {
  const [params] = useSearchParams();
  const [orders, setOrders] = React.useState();

  React.useEffect(() => {
    const paramString = new URLSearchParams({
      name: params.get('name'),
      mobile: params.get('mobile'),
    }).toString();
    axios
      .get(process.env.REACT_APP_SERVER_URL + '/event-order?' + paramString)
      .then((response) => {
        response.data.sort((a, b) => {
          return (
            DateTime.fromISO(b.date).toMillis() -
            DateTime.fromISO(a.date).toMillis()
          );
        });
        setOrders(response.data);
      });
  }, [params]);

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 8, md: 12 },
        pt: 3,
        pb: 10,
      }}>
      <p style={{ fontWeight: 'bold', fontSize: 20 }}>報名紀錄</p>
      <Box
        sx={{
          border: '1px solid rgba(0,0,0,0.25)',
          borderRadius: 1,
          p: 3,
          mb: 2,
        }}>
        <Box sx={{ mb: 1 }}>報名人：{params.get('name')}</Box>
        <Box>
          <span style={{ letterSpacing: '0.6rem' }}>電話:</span>
          {params.get('mobile')}
        </Box>
        <ul>
          <li>
            <a
              href="https://einvoice.ecpay.com.tw/SearchInvoice/Invoice"
              target="_blank"
              rel="noreferrer">
              查詢發票內容/載具歸戶
            </a>
          </li>
          <li>
            <a href="/tutorial" target="_blank" rel="noreferrer">
              如何將電子發票歸戶？
            </a>
          </li>
        </ul>
      </Box>
      <Box>
        {orders &&
          orders.map((order, index) => {
            let status = '尚未付費';
            if (order.receipts?.length > 0) status = '成功報名';
            else if (order.payment?.paid) status = '待開立發票';
            else if (Date.now() > DateTime.fromISO(order.payment.expiry))
              status = '逾期作廢';
            else if (!order.payment?.paymentNo) status = '未取號';

            const receipt = order.receipts?.at(-1);
            return (
              <Accordion key={order._id} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor: 'rgba(0,0,0,0.05)',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: statusColors[status],
                        borderRadius: 5,
                        fontSize: 13,
                        color: 'white',
                        padding: 3,
                        display: 'inline-block',
                        minWidth: 55,
                        textAlign: 'center',
                        marginRight: 20,
                      }}>
                      {status}
                    </span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: 15,
                        display: 'inline-block',
                      }}>
                      {DateTime.fromISO(order.date).toFormat('yyyy-MM-dd')}
                    </span>
                    <span
                      style={{
                        marginRight: 15,
                        display: 'inline-block',
                        textAlign: 'right',
                        minWidth: 55,
                      }}>
                      $
                      {order.items
                        .reduce((sum, item) => sum + item.price, 0)
                        .toLocaleString()}
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      fontSize: {
                        xs: 12,
                        sm: 14,
                        md: 16,
                      },
                      mx: 0,
                      mb: 2,
                      color: 'rgba(0,0,0,0.8)',
                    }}>
                    <Grid item xs={6} md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: 16, fontWeight: 'bold' }}>
                        報名資訊
                      </Typography>
                      <Box>報名編號：{order._id.slice(-6).toUpperCase()}</Box>
                      <Box>
                        報名日期：
                        {DateTime.fromISO(order.date).toFormat('yyyy-MM-dd')}
                      </Box>
                      <Box>
                        <span style={{ letterSpacing: '0.39rem' }}>
                          報名費:
                        </span>
                        $
                        {order.items
                          .reduce((sum, item) => sum + item.price, 0)
                          .toLocaleString()}
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: 16, fontWeight: 'bold' }}>
                        付費資訊
                      </Typography>
                      <Box>
                        付費方式：
                        {order.payment?.paymentType === '超商'
                          ? '超商代碼'
                          : '銀行轉帳'}
                      </Box>
                      {order.payment?.bankCode && (
                        <Box>銀行代碼：{order.payment?.bankCode}</Box>
                      )}
                      {order.payment?.paymentNo && (
                        <Box>轉帳帳號：{order.payment?.paymentNo}</Box>
                      )}
                      {order.payment?.expiry && (
                        <Box>
                          付款期限：
                          {DateTime.fromISO(order.payment?.expiry).toFormat(
                            'yyyy-MM-dd'
                          )}
                        </Box>
                      )}
                    </Grid>
                    {receipt && (
                      <Grid item xs={6} md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: 16, fontWeight: 'bold' }}>
                          發票資訊
                        </Typography>
                        <Box>發票號碼：{receipt.sn}</Box>
                        <Box>
                          開立日期：
                          {DateTime.fromISO(receipt.date).toFormat(
                            'yyyy-MM-dd'
                          )}
                        </Box>
                        <Box>
                          <span style={{ letterSpacing: '0.39rem' }}>
                            隨機碼:
                          </span>
                          {receipt.random}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <Table>
                    <TableBody>
                      {order.items.map((item) =>
                        item.name !== '接駁車' ? (
                          <>
                            <TableRow key={item._id}>
                              <TableCell
                                rowSpan={2}
                                sx={{
                                  display: { xs: 'none', md: 'table-cell' },
                                }}>
                                {item.event}
                              </TableCell>
                              <TableCell
                                rowSpan={2}
                                sx={{
                                  display: { xs: 'none', md: 'table-cell' },
                                }}>
                                {item.name}
                              </TableCell>
                              <TableCell
                                rowSpan={2}
                                sx={{
                                  display: { xs: 'table-cell', md: 'none' },
                                }}>
                                {item.event} {item.name}
                                <br />${item.price.toLocaleString()}
                              </TableCell>
                              <TableCell
                                maxWidth="50%"
                                sx={{
                                  display: { xs: 'none', md: 'table-cell' },
                                }}
                                rowSpan={2}>
                                ${item.price.toLocaleString()}
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    color: '#710c10',
                                    marginRight: 5,
                                    fontWeight: 'bold',
                                  }}>
                                  逝
                                </span>
                                {item.deceaseds.join(' ')}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <span
                                  style={{
                                    color: '#E87A29',
                                    marginRight: 5,
                                    fontWeight: 'bold',
                                  }}>
                                  陽
                                </span>
                                {item.family.join(' ')}
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow key={item._id}>
                            <TableCell
                              sx={{
                                display: { xs: 'none', md: 'table-cell' },
                              }}>
                              {item.event}
                            </TableCell>
                            <TableCell
                              sx={{
                                display: { xs: 'none', md: 'table-cell' },
                              }}>
                              {item.name}
                            </TableCell>
                            <TableCell
                              sx={{
                                display: { xs: 'table-cell', md: 'none' },
                              }}>
                              {item.event} {item.name}
                              <br />${item.price.toLocaleString()}
                            </TableCell>
                            <TableCell
                              maxWidth="50%"
                              sx={{
                                display: { xs: 'none', md: 'table-cell' },
                              }}>
                              ${item.price.toLocaleString()}
                            </TableCell>
                            <TableCell>
                              {`${
                                item.shuttle.date === 0 ? '第一天' : '第二天'
                              } · ${item.shuttle.station}站 · ${
                                item.shuttle.time
                              } · ${item.shuttle.party}人`}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Box>
    </Box>
  );
}

export default Orders;
