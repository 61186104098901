import { DateTime } from 'luxon';
import React from 'react';
import { useCart } from './cart-service';

function useCheckCartInterval({ milli }) {
  const { cart, setCart } = useCart();

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (cart.expiry && cart.expiry < DateTime.now()) {
        console.log('clear cart');
        setCart((prev) => ({
          ...prev,
          items: [],
          expiry: DateTime.now()
            .startOf('day')
            .plus({ day: 1, hours: 2 })
            .toMillis(),
        }));
      }
    }, milli);
    return () => clearInterval(interval);
  }, [cart, setCart, milli]);
}

export default useCheckCartInterval;
