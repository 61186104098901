import { Card, CardContent, Grid, Typography } from '@mui/material';

import { Box } from '@mui/system';
import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function Closed(props) {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.1)',
        position: 'relative',
      }}>
      <Card
        elevation={2}
        sx={{
          p: 2,
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: {
            xs: '90%',
            sm: '500px',
          },
        }}>
        <CardContent>
          <Typography
            variant="h1"
            noWrap
            component="a"
            href=""
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexGrow: 1,
              fontFamily: 'serif',
              fontWeight: 700,
              fontSize: 30,
              letterSpacing: '.3rem',
              color: 'rgb(58,158,156)',
              textDecoration: 'none',
            }}>
            頂福陵園
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <WarningAmberIcon
                sx={{ color: '#DC8282', pt: 0.6, pr: 1 }}
                fontSize="large"
              />
            </Grid>
            <Grid item sx={{ fontSize: 30, py: 3 }}>
              法會報名網頁維護中
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <ul style={{ paddingLeft: 25 }}>
                <li>
                  {process.env.REACT_APP_CLOSED_UNTIL}
                  ：開放線上報名
                </li>
                <li>
                  {process.env.REACT_APP_OPEN_ON}：啟建
                  {process.env.REACT_APP_EVENT}法會
                </li>
              </ul>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Closed;
