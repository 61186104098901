import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Navigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import { useCart } from './cart-service';

const styles = {
  label: {
    display: 'inline-block',
    width: 150,
  },
};

function Checkout(props) {
  const { cart, setCart } = useCart();
  const [redirect, setRedirect] = React.useState();
  const form = React.useRef();
  const [order, setOrder] = React.useState({
    name: cart.name || '',
    mobile: cart.mobile || '',
    paymentType: cart.paymentType || '超商',
    email: cart.email || '',
    checked: false,
    error: {},
    open: false,
  });

  React.useEffect(() => {
    if (redirect) {
      form.current.submit();
    }
  }, [redirect]);

  function validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) return true;
    return false;
  }
  function validateMobile(mobile) {
    if (/^09\d{8}$/.test(mobile)) return true;
    return false;
  }

  async function handleSubmit() {
    const errors = {};

    if (!order.name) errors.name = '請輸入姓名';
    if (!validateMobile(order.mobile)) errors.mobile = '手機號碼格式錯誤';
    if (!validateEmail(order.email)) errors.email = 'Email 格式錯誤';
    if (!order.mobile) errors.mobile = '請輸入手機號碼';
    if (!order.email) errors.email = '請輸入 Email';
    if (!order.checked) errors.checked = '請勾選同意';

    if (Object.keys(errors).length > 0) return setOrder({ ...order, errors });

    setOrder({ ...order, inProcess: true });
    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + '/event-order',
      {
        date: Date.now(),
        name: order.name,
        mobile: order.mobile,
        email: order.email,
        items: cart.items,
        payment: {
          paymentType: order.paymentType,
        },
      }
    );
    setCart({
      ...cart,
      items: [],
      name: order.name,
      mobile: order.mobile,
      email: order.email,
      paymentType: order.paymentType,
    });
    setRedirect(response.data);
  }

  if (cart.items.length === 0 && !redirect) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 8, md: 12 },
        pt: 3,
        pb: 10,
      }}>
      <Box sx={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: 1, mb: 2 }}>
        <Box
          sx={{
            p: 2,
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderBottom: '1px solid rgba(0,0,0,0.2)',
          }}>
          報名摘要
        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container alignItems="center">
            <Grid item>報名費總計：</Grid>
            <Grid item sx={{ letterSpacing: '0.05rem' }}>
              $
              {cart.items
                .reduce((sum, item) => sum + item.price, 0)
                .toLocaleString()}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: 1, mb: 2 }}>
        <Box
          sx={{
            p: 2,
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderBottom: '1px solid rgba(0,0,0,0.2)',
          }}>
          付費資訊
        </Box>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={styles.label}>姓名</Box>
              <TextField
                variant="outlined"
                size="small"
                value={order.name}
                onChange={(event) =>
                  setOrder({ ...order, name: event.target.value })
                }
                error={!!order.errors?.name}
                helperText={order.errors?.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={styles.label}>行動電話</Box>
              <TextField
                variant="outlined"
                size="small"
                value={order.mobile}
                onChange={(event) =>
                  setOrder({ ...order, mobile: event.target.value })
                }
                error={!!order.errors?.mobile}
                helperText={order.errors?.mobile}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={styles.label}>Email</Box>
              <TextField
                variant="outlined"
                size="small"
                value={order.email}
                onChange={(event) =>
                  setOrder({ ...order, email: event.target.value })
                }
                error={!!order.errors?.email}
                helperText={order.errors?.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={styles.label}>付費方式</Box>
              <FormControl size="small" fullWidth>
                <Select
                  value={order.paymentType}
                  onChange={(event) =>
                    setOrder({ ...order, paymentType: event.target.value })
                  }>
                  <MenuItem value="超商">超商代碼</MenuItem>
                  <MenuItem value="匯款">銀行轉帳</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={!!order.errors?.checked ? { color: 'red' } : null}>
        <Checkbox
          checked={order.checked}
          onChange={() =>
            setOrder(() => {
              const updated = { ...order };
              updated.checked = !updated.checked;
              if (updated.checked && updated.errors?.checked) {
                delete updated.errors.checked;
              }
              return updated;
            })
          }
        />
        <span>
          本人同意
          <span
            style={{
              color: !!order.errors?.checked ? 'red' : 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginLeft: 5,
            }}
            onClick={() => setOrder({ ...order, open: true })}>
            頂福陵園法會報名須知與條款
          </span>
          。
        </span>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="info"
          onClick={handleSubmit}
          size="large"
          disabled={order.inProcess}
          fullWidth>
          確認報名
        </Button>
      </Box>
      <form
        ref={form}
        action="https://core.newebpay.com/MPG/mpg_gateway"
        method="POST">
        <input type="hidden" name="MerchantID" value="MS3550981266" />
        <input type="hidden" name="TradeInfo" value={redirect?.TradeInfo} />
        <input type="hidden" name="TradeSha" value={redirect?.TradeSha} />
        <input type="hidden" name="Version" value="1.5" />
      </form>
      <Dialog
        open={order.open}
        onClose={() => setOrder({ ...order, open: false })}>
        <DialogTitle>
          法會報名須知與條款
          <IconButton
            onClick={() => setOrder({ ...order, open: false })}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ li: { mb: 2 } }}>
          <ol>
            <li>
              同意本次消費透過綠界科技發票平台開立電子發票並儲存於會員載具。
            </li>
            <li>
              同意如需辦理退費，最遲應於法會前兩個禮拜前提出，並支付退費手續費新台幣200元整。
            </li>
            <li>同意法會前兩個禮拜內報名無法退費。</li>
            <li>同意若因為輸入錯誤導致牌位錯誤無法退費。</li>
            <li>
              同意辦理退費手續時，由頂福事業股份有限公司代為處理發票及銷貨退回折讓單，以加速退費作業。
            </li>
          </ol>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Checkout;
