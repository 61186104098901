import { Button, Grid, IconButton } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import getProducts from './product-list';
import { useCart } from './cart-service';

function Cart(props) {
  const { cart, setCart } = useCart();
  const navigate = useNavigate();

  function handleDeleteItem(timestamp) {
    const updatedItems = cart.items.filter(
      (item) => item.timestamp !== timestamp
    );
    setCart({
      ...cart,
      items: updatedItems,
    });
    if (updatedItems.length === 0) navigate('/');
  }

  if (cart.items.length === 0) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 8, md: 12 },
        pt: 3,
        pb: 10,
      }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: 'block', md: 'none' } }}
          maxWidth>
          <Summary cart={cart} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                {cart.items.map((item) => (
                  <CartItem
                    key={item.timestamp}
                    item={item}
                    onDelete={() => handleDeleteItem(item.timestamp)}
                  />
                ))}
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          sx={{ display: { xs: 'none', md: 'block' } }}
          maxWidth>
          <Summary cart={cart} />
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = {
  label: {
    fontSize: 13,
    color: '#219EBC',
    margin: 0,
    marginBottom: 5,
  },
  names: {
    margin: 0,
    marginBottom: 15,
  },
};

function Summary({ cart }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        border: '1px solid rgba(0,0,0,0.3)',
        borderRadius: 1,
      }}>
      <Box
        sx={{
          px: 2,
          py: 1,
          borderBottom: '1px solid rgba(0,0,0,0.3)',
          backgroundColor: 'rgba(0,0,0,0.05)',
        }}>
        報名摘要
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container alignItems="center" sx={{ fontSize: 18 }}>
          <Grid item>報名費總計：</Grid>
          <Grid item sx={{ textAlign: 'right' }}>
            $
            {cart.items
              .reduce((sum, item) => sum + item.price, 0)
              .toLocaleString()}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="error"
          sx={{ mb: 2 }}
          size="large"
          onClick={() => navigate('/checkout')}
          fullWidth>
          付費
        </Button>
        <Button
          variant="outlined"
          color="error"
          size="large"
          onClick={() => navigate('/')}
          fullWidth>
          返回首頁
        </Button>
      </Box>
    </Box>
  );
}

function CartItem({ item, onDelete }) {
  const products = getProducts(process.env.REACT_APP_EVENT);
  return (
    <Box
      sx={{
        width: '100%',
        mb: 3,
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: 1,
      }}>
      <Box
        sx={{
          fontSize: 20,
          p: 2,
          borderBottom: '1px solid rgba(0,0,0,0.2)',
        }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{`${item.name}`}</Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            ${item.price.toLocaleString()}
            <IconButton color="error" onClick={onDelete} sx={{ ml: 2 }}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {item.name !== '接駁車' ? (
                <img
                  width="100%"
                  src={products[item.name].image}
                  alt={item.name}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '260px',
                    border: '1.5px dashed rgba(0,0,0,0.5)',
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <div style={{ color: 'rgba(0,0,0,0.5)', fontSize: 20 }}>
                    接駁車
                  </div>
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <div style={{ marginBottom: 20 }}>{`${item.event.substr(
              0,
              3
            )}年${item.event.substr(3)}法會`}</div>
            {item.name !== '接駁車' ? (
              <>
                <p style={styles.label}>被超薦人：</p>
                <p style={styles.names}>{item.deceaseds.join(', ')}</p>
                <p style={styles.label}>陽上：</p>
                <p style={styles.names}>{item.family.join(' ')}</p>
              </>
            ) : (
              <>
                <p style={styles.label}>日期：</p>
                <p style={styles.names}>
                  {item.shuttle.date === 0 ? '第一天' : '第二天'}
                </p>
                <p style={styles.label}>接駁站：</p>
                <p style={styles.names}>{item.shuttle.station}</p>
                <p style={styles.label}>時間：</p>
                <p style={styles.names}>{item.shuttle.time}</p>
                <p style={styles.label}>人數：</p>
                <p style={styles.names}>{item.shuttle.party}</p>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Cart;
