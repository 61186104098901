import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import React from 'react';

function Tutorial(props) {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 8, md: 12 },
        pt: 3,
        pb: 10,
        hr: {
          margin: '20px 0',
        },
      }}>
      <Box sx={{ my: 4, fontSize: 20, fontWeight: 'bold' }}>電子發票歸戶</Box>
      <p>
        依據結帳時提供之手機號碼產生固定會員載具編號，只需依照下列步驟歸戶一次，未來使用同手機號碼報名，發票會自動歸戶。
      </p>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          1、於訂單記錄頁面點選「查詢發票內容」或在瀏覽器直接輸入網址：
          <a href="https://einvoice.ecpay.com.tw/SearchInvoice/Invoice">
            https://einvoice.ecpay.com.tw/SearchInvoice/Invoice
          </a>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            width="70%"
            src="https://dinfucemstorage.blob.core.windows.net/document/dinfucem/tutorial-1.png"
            alt="tutorial step 1"
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          2、輸入發票號碼、隨機碼、及驗證碼
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            width="90%"
            src="https://dinfucemstorage.blob.core.windows.net/document/dinfucem/tutorial-2.png"
            alt="tutorial step 2"
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          3、點選「歸戶」前往財政部電子發票整合服務平台網頁
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            width="90%"
            src="https://dinfucemstorage.blob.core.windows.net/document/dinfucem/tutorial-3.png"
            alt="tutorial step 3"
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          4、輸入「手機號碼」及財政部電子發票平台密碼進行載具歸戶
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            width="90%"
            src="https://dinfucemstorage.blob.core.windows.net/document/dinfucem/tutorial-4.png"
            alt="tutorial step 4"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Tutorial;
